import { KcmProfile, PPState } from "@/types";
import { Commit } from "vuex";

const getDefaultState = (): PPState => {
  const storage = window.localStorage.getItem("profile") ?? "";
  const pp = storage ? JSON.parse(storage) : null;
  if (pp) {
    return {
      personalization: pp,
      loading_profile: false,
    };
  }
  return {
    personalization: null,
    loading_profile: false,
  };
};

export const state = getDefaultState();

export const mutations = {
  PERSONALIZATION(state: PPState, newValue: KcmProfile): void {
    state.personalization = newValue;
    if (state.personalization) {
      state.personalization.loadedAt = Date.now();
    }
    saveState("profile", state.personalization);
  },

  LOADING_PROFILE(state: PPState, newValue: boolean): void {
    state.loading_profile = newValue;
  },
};

export const getters = {
  profilePP(state: PPState): PPState["personalization"] {
    return state.personalization;
  },

  ppCode(state: PPState): string | null {
    if (state.personalization?.basic?.pp_code?.value) {
      return state.personalization.basic.pp_code.value;
    }
    return null;
  },

  pausedAt(state: PPState): string | null {
    if (state.personalization?.basic?.paused_at?.value) {
      return new Date(
        state.personalization.basic.paused_at.value
      ).toISOString();
    }
    return null;
  },

  title1(state: PPState): string {
    if (state.personalization?.blog?.headertext?.value) {
      return state.personalization.blog.headertext.value;
    }
    return "Real Estate with";
  },
  title2(state: PPState): string {
    if (state.personalization?.blog?.pos_A?.value) {
      return state.personalization.blog.pos_A.value.toUpperCase();
    }
    return "KEEPING CURRENT MATTERS";
  },

  loadingPP(state: PPState): boolean {
    return state.loading_profile;
  },
  fbPixel(state: PPState): string | null {
    if (state.personalization?.blog?.fbpixel?.value) {
      return state.personalization.blog.fbpixel.value;
    }
    return null;
  },
  primaryColor(state: PPState): string | null {
    if (state.personalization?.basic?.primary_color?.value)
      return state.personalization.basic.primary_color.value;
    return null;
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  setProfilePP({ commit }: { commit: Commit }, newValue: KcmProfile): void {
    commit("PERSONALIZATION", newValue);
  },

  setLoadingPP({ commit }: { commit: Commit }, newValue: boolean): void {
    commit("LOADING_PROFILE", newValue);
  },
};

function saveState(key: string, state: any): void {
  window.localStorage.setItem(key, JSON.stringify(state));
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
